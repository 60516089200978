<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin chương trình tích điếm'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <!-- Input session -->
              <b-container class="bv-example-row">
                <b-row>
                  <b-col cols="6">
                    <b-form-group id="input-group-name">
                      <label for="input-code">Tên chương trình:</label>
                      <span class="text-danger">*</span>
                      <b-form-input
                        size="sm"
                        id="input-name"
                        v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        placeholder="Nhập tên"
                        aria-describedby="input-live-help input-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-live-feedback">
                        Nhập tên chương trình với ít nhất 3 ký tự!
                      </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="input-group-code">
                      <label for="input-code"
                        >Cửa hàng áp dụng( Để trống nếu muốn áp dụng cho tất cả
                        cửa hàng):</label
                      >
                      <vue-multi-select
                        style="margin-bottom: 2rem"
                        v-model="selectedStore"
                        :options="listStore"
                        placeholder="Chọn danh mục"
                        option-label="name"
                      ></vue-multi-select>
                    </b-form-group>
                    <label style="font-weight: 600" for="input-description"
                      >Mô tả:</label
                    >
                    <b-form-textarea
                      id="textarea-description"
                      v-model="description"
                      placeholder="Nhập mô tả"
                      rows="6"
                    ></b-form-textarea>
                  </b-col>

                  <b-col cols="3">
                    <b-form-group id="input-group-start-date">
                      <label for="input-start-date">Ngày bắt đầu:</label>
                      <date-picker
                        placeholder="Từ ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="startDate"
                      ></date-picker>
                    </b-form-group>

                    <b-form-group id="input-group-end-date">
                      <label for="input-end-date">Ngày kết thúc:</label>
                      <date-picker
                        placeholder="Đến ngày"
                        class="form-control form-control-sm"
                        :config="dpConfigs.date"
                        v-model="endDate"
                      ></date-picker>
                    </b-form-group>
                  </b-col>
                  <b-col cols="3">
                    <label style="font-weight: 600" for="input-status"
                      >Trạng thái:</label
                    >
                    <b-form-select
                      size="sm"
                      id="input-3"
                      v-model="selectedStatus"
                      :options="listStatus"
                      required
                      value-field="id"
                      text-field="name"
                      disabled-field="notEnabled"
                    ></b-form-select>
                  </b-col>
                </b-row>

                <b-row class="mt-4">
                  <b-col>
                    <div>
                      <table
                        class="table table-bordered table-vertical-center table-hover"
                      >
                        <thead>
                          <tr>
                            <th
                              style="textalign: center; color: rgb(24, 28, 50)"
                            >
                              Hóa đơn tối thiểu
                            </th>
                            <th>Hóa đơn tối đa</th>
                            <th>Kiểu tích điểm</th>
                            <th>Giá trị tích điểm</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody
                          v-for="(item, index) in listPointProgramDetail"
                          :key="item.id"
                        >
                          <PointProgram
                            v-bind:item="item"
                            v-bind:index="index"
                            v-on:updateItem="updateValue"
                            v-on:deleteItem="deleteValue"
                            v-on:addItem="addNewValue"
                            v-on:cancelAdd="cancelAdd"
                          />
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
                <button
                  type="button"
                  class="btn btn-primary btn-sm mt-4"
                  @click="addRow"
                  v-if="checkPermission('POINT_UPDATE')"
                >
                  <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm
                  dòng
                </button>
              </b-container>
            </b-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="onSubmit"
              v-if="checkPermission('POINT_UPDATE')"
              >Lưu</b-button
            >
            <!-- </b-col> -->
            <!-- <b-col lg="2" class="pb-2"> -->
            <router-link to="/points" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
            <!-- </b-col> -->
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style>
.multi-select-wrapper {
  flex: 1 1 auto;
}
.center-label label {
  margin-bottom: 0px;
}
th.pointType {
  width: 10%;
}
th.maxValue {
  width: 25%;
}
th.minValue,
th.value {
  width: 25%; /* Not necessary, since only 70% width remains */
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import PointProgram from '../../components/PointProgram.vue';
// import { removeAccents } from './../../../utils/common';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import moment from 'moment';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import VueMultiSelect from 'vue-simple-multi-select';
import { unMaskPrice } from './../../../utils/common';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import localData from '../../../utils/saveDataToLocal';

export default {
  mixins: [validationMixin],
  data() {
    return {
      selectedStore: [],
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      startDate: '',
      endDate: '',
      listPointProgramDetail: [],
      form: {
        name: '',
      },
      description: '',
      listStore: [],
      stores: '',
      listStatus: [
        {
          id: 1,
          name: 'Hoạt động',
        },
        {
          id: 2,
          name: 'Đã khóa',
        },
      ],
      selectedStatus: 1,
      count: 0,
      id: 0,
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    VueMultiSelect,
    PointProgram,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Bộ phụ kiện tặng kèm', route: '/accessories' },
      {
        title: 'Thêm mới bộ phụ kiện tặng kèm',
        route: '/accessories/add-accessory',
      },
    ]);
    this.id = this.$route.query.id;
  },
  created() {
    this.getListStore();
    this.getPointProgramById();
  },
  methods: {
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    getListStore() {
      ApiService.setHeader();
      ApiService.get('/stores/getStores')
        .then(({ data }) => {
          if (data.status === 1) {
            this.listStore = data.data;
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    formatMoney(number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      }).then((result) => {
        if (result.dismiss != 'cancel') {
          for (let i = 0; i < this.accessoryProductItems.length; i++) {
            if (this.accessoryProductItems[i].productId === item.productId) {
              this.accessoryProductItems.splice(i, 1);
            }
          }
        }
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    onSubmit: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let isActive = 1;
      if (this.selectedStatus === 2) {
        isActive = 0;
      }
      let storeIds = '-1';
      if (this.selectedStore.length > 0) {
        storeIds = '';
        this.selectedStore.forEach((element, index) => {
          if (index === this.selectedStore.length - 1) {
            storeIds += element.id;
          } else {
            storeIds += element.id + ', ';
          }
        });
      }
      this.listPointProgramDetail.forEach((element) => {
        element.minValue = unMaskPrice(element.minValue);
        element.maxValue = unMaskPrice(element.maxValue);
      });
      let data = {
        id: this.id,
        name: this.$v.form.name.$model,
        description: this.description,
        listDetail: this.listPointProgramDetail,
        startDate: this.startDate
          ? moment(this.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        endDate: this.endDate
          ? moment(this.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : null,
        storeIds: storeIds,
        status: isActive,
      };
      //   console.log(`List selected store: ${JSON.stringify(data)}`);
      ApiService.setHeader();
      ApiService.put('pointProgram', data).then((response) => {
        const { status, message } = response.data;
        if (status === 1) {
          this.makeToastSuccess('Cập nhật chương trình tích điểm thành công!');
          setTimeout(() => {
            this.$router.push({
              path: '/points',
            });
          }, 1000);
        } else {
          this.makeToastFaile(message);
        }
      });
    },
    addRow() {
      if (this.listPointProgramDetail.length > 0) {
        this.count =
          this.listPointProgramDetail[this.listPointProgramDetail.length - 1]
            .id + 1;
      }
      let data = {
        id: this.count,
        minValue: 0,
        maxValue: 0,
        pointType: 1,
        value: 0,
      };
      this.listPointProgramDetail.push(data);
    },
    cancelAdd() {
      this.listPointProgramDetail.shift();
    },
    addNewValue(item) {
      this.listPointProgramDetail[
        this.listPointProgramDetail.length - 1
      ].minValue = unMaskPrice(item.minValue);
      this.listPointProgramDetail[
        this.listPointProgramDetail.length - 1
      ].maxValue = unMaskPrice(item.maxValue);
      this.listPointProgramDetail[
        this.listPointProgramDetail.length - 1
      ].value = item.value;
      this.listPointProgramDetail[
        this.listPointProgramDetail.length - 1
      ].pointType = item.pointType;
    },
    updateValue(item) {
      this.listPointProgramDetail.forEach((element, index) => {
        if (element.id === item.id) {
          this.listPointProgramDetail[index].id = item.id;
          this.listPointProgramDetail[index].minValue = unMaskPrice(
            item.minValue
          );
          this.listPointProgramDetail[index].maxValue = unMaskPrice(
            item.maxValue
          );
          this.listPointProgramDetail[index].value = item.value;
          this.listPointProgramDetail[index].pointType = item.pointType;
        }
      });
    },
    deleteValue(id) {
      for (var i = 0; i < this.listPointProgramDetail.length; i++) {
        if (this.listPointProgramDetail[i].id === id) {
          this.listPointProgramDetail.splice(i, 1);
        }
      }
    },
    getPointProgramById: async function () {
      await ApiService.setHeader();
      await ApiService.get(`pointProgram/${this.id}`).then((res) => {
        this.$v.form.name.$model = res.data.data.name;
        this.description = res.data.data.desciption;
        this.startDate = res.data.data.startDate
          ? moment(res.data.data.startDate).format('DD/MM/YYYY')
          : null;
        this.endDate = res.data.data.endDate
          ? moment(res.data.data.endDate).format('DD/MM/YYYY')
          : null;
        this.listPointProgramDetail = res.data.data.listDetail;
        this.selectedStatus = res.data.data.status === true ? 1 : 2;
        this.selectedStore = res.data.data.storeIds;
      });
    },
    checkPermission(condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>

<style>
.rounded {
  border-radius: 0 !important;
}

.form-group label {
  font-weight: 600;
}
</style>
